var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "div-form-main cd-note-form",
    },
    [
      _c(
        "div",
        { staticClass: "div-form-main-btn" },
        [
          _vm.propsView === "CREATE_CD_NOTE" &&
          _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
            ? _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitCdNote(true)
                    },
                  },
                },
                [_vm._v("Submit")]
              )
            : _vm._e(),
          _vm.propsView === "EDIT_CD_NOTE" &&
          !_vm.isCdFormAllFieldsDisabled &&
          _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
            ? _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitCdNote(false)
                    },
                  },
                },
                [_vm._v("Submit")]
              )
            : _vm._e(),
          _vm.propsView === "EDIT_CD_NOTE" &&
          _vm.isCdFormAllFieldsDisabled &&
          _vm.hasPermission(_vm.$permissionFunctionCode.EDIT) &&
          (_vm.cdNoteBasInfo.status ===
            _vm.CDNOTE_STATUS.AUTHORIZED_SIGNATORY_REJECTED ||
            _vm.cdNoteForm.status ===
              _vm.CDNOTE_STATUS.CREDIT_AUTHORIZATION_REJECTED)
            ? _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.isCdFormAllFieldsDisabled = false
                    },
                  },
                },
                [_vm._v("Edit")]
              )
            : _vm._e(),
          !_vm.isViewOnly
            ? _c(
                "el-button",
                {
                  attrs: { round: "", outline: "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCloseCdNoteForm("CLOSE")
                    },
                  },
                },
                [_vm._v("Close")]
              )
            : _vm._e(),
        ],
        1
      ),
      ["CREATE_CD_NOTE", "EDIT_CD_NOTE", "MAIN_VIEW"].includes(_vm.propsView)
        ? _c(
            "div",
            { staticClass: "div-form-main-container" },
            [
              _c(
                "el-form",
                {
                  ref: "cdNoteForm",
                  attrs: { model: _vm.cdNoteForm, rules: _vm.rules },
                },
                [
                  _c("div", { staticClass: "basic-card-block margin-top-0" }, [
                    _c("h1", [
                      _vm._v(
                        _vm._s(
                          _vm.cdNoteForm.noteType === "CREDIT_NOTE"
                            ? "Credit"
                            : "Debit"
                        ) + " Note Information"
                      ),
                    ]),
                    _c(
                      "div",
                      [
                        _vm.propsView === "CREATE_CD_NOTE"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "el-button-select-invoice",
                                attrs: {
                                  round: "",
                                  outline: "",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.dialogSelectInvoice = true
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "span-red-asterik" },
                                  [_vm._v("*")]
                                ),
                                _vm._v("Select Invoice "),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "div-frac-15" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Invoice Number",
                                  prop: "invoiceNo",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.cdNoteForm.invoiceNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.cdNoteForm, "invoiceNo", $$v)
                                    },
                                    expression: "cdNoteForm.invoiceNo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Company Name",
                                  prop: "customerCompanyName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.cdNoteForm.customerCompanyName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.cdNoteForm,
                                        "customerCompanyName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "cdNoteForm.customerCompanyName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Vessel Name/ Terminal",
                                  prop: "orderInfo",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.cdNoteForm.orderInfo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.cdNoteForm, "orderInfo", $$v)
                                    },
                                    expression: "cdNoteForm.orderInfo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Note Date", prop: "noteDate" },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    disabled: _vm.isCdFormAllFieldsDisabled,
                                  },
                                  model: {
                                    value: _vm.cdNoteForm.noteDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.cdNoteForm, "noteDate", $$v)
                                    },
                                    expression: "cdNoteForm.noteDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Due Date",
                                  prop: "noteDueDate",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    disabled: _vm.isCdFormAllFieldsDisabled,
                                  },
                                  on: {
                                    change: (e) =>
                                      (_vm.cdNoteForm.noteDueDate =
                                        e.toISOString()),
                                  },
                                  model: {
                                    value: _vm.cdNoteForm.noteDueDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.cdNoteForm,
                                        "noteDueDate",
                                        $$v
                                      )
                                    },
                                    expression: "cdNoteForm.noteDueDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.cdNoteForm.noteType === "DEBIT_NOTE"
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: "term",
                                    attrs: { label: "Terms", prop: "term" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.isCdFormAllFieldsDisabled,
                                        },
                                        on: { change: _vm.handleChangeTerm },
                                        model: {
                                          value: _vm.cdNoteForm.term,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.cdNoteForm,
                                              "term",
                                              $$v
                                            )
                                          },
                                          expression: "cdNoteForm.term",
                                        },
                                      },
                                      _vm._l(
                                        _vm.PAYMENT_TERM_OPTIONS,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.cdNoteForm.noteType === "DEBIT_NOTE" &&
                            _vm.cdNoteForm.term === "SPECIFIC_DATE"
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: "termDate",
                                    attrs: {
                                      label: "Term Date",
                                      prop: "termDate",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        disabled: _vm.isCdFormAllFieldsDisabled,
                                        type: "date",
                                      },
                                      model: {
                                        value: _vm.cdNoteForm.termDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.cdNoteForm,
                                            "termDate",
                                            $$v
                                          )
                                        },
                                        expression: "cdNoteForm.termDate",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.cdNoteForm.noteType === "DEBIT_NOTE" &&
                            _vm.cdNoteForm.term !== "SPECIFIC_DATE"
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Credit Days",
                                      prop: "creditDay",
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        disabled:
                                          _vm.isCdFormAllFieldsDisabled ||
                                          _vm.cdNoteForm.term === "IMMEDIATE",
                                        controls: false,
                                        precision: 0,
                                        min: 0,
                                      },
                                      model: {
                                        value: _vm.cdNoteForm.creditDay,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.cdNoteForm,
                                            "creditDay",
                                            $$v
                                          )
                                        },
                                        expression: "cdNoteForm.creditDay",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "div-frac-1" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Reason", prop: "reason" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: _vm.isCdFormAllFieldsDisabled,
                                  },
                                  model: {
                                    value: _vm.cdNoteForm.reason,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.cdNoteForm, "reason", $$v)
                                    },
                                    expression: "cdNoteForm.reason",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "basic-card-block" }, [
                    _c("h1", [_vm._v("Item")]),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "div-frac-14" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Item Type", prop: "itemType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled:
                                      (_vm.propsView === "CREATE_CD_NOTE" &&
                                        !_vm.cdNoteForm.invoiceNo) ||
                                      _vm.isCdFormAllFieldsDisabled,
                                  },
                                  on: {
                                    change: _vm.handleCdNoteItemTypeSelect,
                                  },
                                  model: {
                                    value: _vm.cdNoteForm.itemType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.cdNoteForm, "itemType", $$v)
                                    },
                                    expression: "cdNoteForm.itemType",
                                  },
                                },
                                _vm._l(_vm.itemTypeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Description",
                                prop: "description",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    "value-key": "id",
                                    "allow-create":
                                      _vm.cdNoteForm.itemType === "OTHER_COST",
                                    filterable: "",
                                    disabled:
                                      (_vm.propsView === "CREATE_CD_NOTE" &&
                                        !_vm.cdNoteForm.itemType) ||
                                      _vm.isCdFormAllFieldsDisabled,
                                  },
                                  on: { change: _vm.handleCdNoteItemSelect },
                                  model: {
                                    value: _vm.cdNoteForm.description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.cdNoteForm,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "cdNoteForm.description",
                                  },
                                },
                                _vm._l(_vm.dropDownDescList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.description,
                                      value: item,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.cdNoteForm.itemType === "PRODUCT"
                            ? _c(
                                "div",
                                { staticClass: "price-uom-input" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Quantity",
                                        prop: "quantity",
                                      },
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          controls: false,
                                          precision: 3,
                                          min: 0,
                                          disabled:
                                            _vm.isCdFormAllFieldsDisabled,
                                        },
                                        on: { blur: _vm.calculateSummaryInfo },
                                        model: {
                                          value: _vm.cdNoteForm.quantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.cdNoteForm,
                                              "quantity",
                                              $$v
                                            )
                                          },
                                          expression: "cdNoteForm.quantity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Unit", prop: "unit" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            disabled:
                                              (_vm.propsView ===
                                                "CREATE_CD_NOTE" &&
                                                !_vm.cdNoteForm.invoiceNo) ||
                                              _vm.isCdFormAllFieldsDisabled,
                                          },
                                          model: {
                                            value: _vm.cdNoteForm.unit,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.cdNoteForm,
                                                "unit",
                                                $$v
                                              )
                                            },
                                            expression: "cdNoteForm.unit",
                                          },
                                        },
                                        _vm._l(
                                          _vm.UNIT_OPTIONS,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Quantity",
                                    prop: "quantity",
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      controls: false,
                                      precision: 3,
                                      min: 0,
                                      disabled: _vm.isCdFormAllFieldsDisabled,
                                    },
                                    on: { blur: _vm.calculateSummaryInfo },
                                    model: {
                                      value: _vm.cdNoteForm.quantity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.cdNoteForm,
                                          "quantity",
                                          $$v
                                        )
                                      },
                                      expression: "cdNoteForm.quantity",
                                    },
                                  }),
                                ],
                                1
                              ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Price", prop: "price" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  controls: false,
                                  precision: 3,
                                  min: 0,
                                  disabled: _vm.isCdFormAllFieldsDisabled,
                                },
                                on: { blur: _vm.calculateSummaryInfo },
                                model: {
                                  value: _vm.cdNoteForm.price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cdNoteForm, "price", $$v)
                                  },
                                  expression: "cdNoteForm.price",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Amount", prop: "amount" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  controls: false,
                                  precision: 2,
                                  min: 0,
                                  disabled: true,
                                },
                                on: { blur: _vm.calculateSummaryInfo },
                                model: {
                                  value: _vm.cdNoteForm.amount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cdNoteForm, "amount", $$v)
                                  },
                                  expression: "cdNoteForm.amount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Supply Date",
                                required: true,
                                prop: "supplyDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  disabled: _vm.isCdFormAllFieldsDisabled,
                                },
                                model: {
                                  value: _vm.cdNoteForm.supplyDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cdNoteForm, "supplyDate", $$v)
                                  },
                                  expression: "cdNoteForm.supplyDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.cdNoteForm.itemType === "PRODUCT"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Receipt No",
                                    prop: "receiptNo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.cdNoteForm.receiptNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.cdNoteForm,
                                          "receiptNo",
                                          $$v
                                        )
                                      },
                                      expression: "cdNoteForm.receiptNo",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "basic-card-block" }, [
                    _c("h1", [_vm._v("Payment Information")]),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "div-frac-14" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Exchange Currency",
                                prop: "exchangeCurrency",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isCdFormAllFieldsDisabled,
                                  },
                                  model: {
                                    value: _vm.cdNoteForm.exchangeCurrency,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.cdNoteForm,
                                        "exchangeCurrency",
                                        $$v
                                      )
                                    },
                                    expression: "cdNoteForm.exchangeCurrency",
                                  },
                                },
                                _vm._l(_vm.currencyList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.code,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Exchange Currency Rate",
                                prop: "exchangeCurrencyRate",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  controls: false,
                                  precision: 3,
                                  min: 0,
                                  disabled: _vm.isCdFormAllFieldsDisabled,
                                },
                                on: { blur: _vm.calculateSummaryInfo },
                                model: {
                                  value: _vm.cdNoteForm.exchangeCurrencyRate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.cdNoteForm,
                                      "exchangeCurrencyRate",
                                      $$v
                                    )
                                  },
                                  expression: "cdNoteForm.exchangeCurrencyRate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "GST Type", prop: "gstType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isCdFormAllFieldsDisabled,
                                  },
                                  on: { change: _vm.handleGstTypeSelect },
                                  model: {
                                    value: _vm.cdNoteForm.gstType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.cdNoteForm, "gstType", $$v)
                                    },
                                    expression: "cdNoteForm.gstType",
                                  },
                                },
                                _vm._l(_vm.GST_TYPE_OPTIONS, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "GST %", prop: "gstPercentage" },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  controls: false,
                                  precision: 2,
                                  min: 0,
                                  disabled: "",
                                },
                                on: { blur: _vm.calculateSummaryInfo },
                                model: {
                                  value: _vm.cdNoteForm.gstPercentage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.cdNoteForm,
                                      "gstPercentage",
                                      $$v
                                    )
                                  },
                                  expression: "cdNoteForm.gstPercentage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "div-form-main-right-summary" }, [
                _c("div", [
                  _vm.propsView === "EDIT_CD_NOTE"
                    ? _c("h4", [
                        _vm._v(
                          _vm._s(
                            _vm.cdNoteForm.noteType === "CREDIT_NOTE"
                              ? "Credit"
                              : "Debit"
                          ) + " Note Number"
                        ),
                      ])
                    : _vm._e(),
                  _vm.propsView === "EDIT_CD_NOTE"
                    ? _c(
                        "div",
                        { staticClass: "div-cd-note-payment-summary-note-no" },
                        [
                          _c("h1", [_vm._v(_vm._s(_vm.noteNo))]),
                          _c("i", {
                            staticClass: "el-icon-document",
                            on: {
                              click: function ($event) {
                                return _vm.previewFile(_vm.cdNotePdfFileId)
                              },
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm.propsView === "EDIT_CD_NOTE" ||
                  _vm.propsView === "MAIN_VIEW"
                    ? _c(
                        "div",
                        { staticClass: "div-cd-note-payment-summary-top" },
                        [
                          _c("div", [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.cdNoteForm.noteType === "CREDIT_NOTE"
                                    ? "Credit"
                                    : "Debit"
                                ) + " Note Status"
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "status-tag",
                                style: _vm.getStatusTagStyle(
                                  _vm.cdNoteBasInfo.status
                                ),
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.capitalizeFirstLetter(
                                        _vm.cdNoteForm.status,
                                        "_"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("div", [
                            _c("p", [_vm._v("Created On")]),
                            _c("p", { staticClass: "p-cd-note-summary-date" }, [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .$moment(_vm.cdNoteBasInfo.createdAt)
                                    .format("DD-MM-YYYY HH:mm")
                                )
                              ),
                            ]),
                          ]),
                          _vm.cdNoteBasInfo.dateApproved
                            ? _c("div", [
                                _c("p", [_vm._v("Date Approved")]),
                                _c(
                                  "p",
                                  { staticClass: "p-cd-note-summary-date" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm
                                          .$moment(
                                            _vm.cdNoteBasInfo.dateApproved
                                          )
                                          .format("DD-MM-YYYY HH:mm")
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c("h4", [
                    _vm._v(
                      _vm._s(
                        _vm.cdNoteForm.noteType === "CREDIT_NOTE"
                          ? "Credit"
                          : "Debit"
                      ) + " Note Summary"
                    ),
                  ]),
                  _c("div", { staticClass: "div-cd-note-payment-currency" }, [
                    _c("p", [
                      _vm._v(
                        "Payment Currency: " +
                          _vm._s(_vm.cdNoteForm.paymentCurrency || "N.A.")
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "div-cd-note-payment-summary" }, [
                    _c("div", [
                      _c("p", [_vm._v("Subtotal")]),
                      _vm._m(0),
                      _vm.cdNotePaymentInfo.subTotal
                        ? _c("p", { staticClass: "p-cd-note-summary-amt" }, [
                            _vm._v(
                              _vm._s(
                                `${
                                  _vm.cdNoteForm.paymentCurrency
                                } ${_vm.numberWithCommas(
                                  _vm.cdNotePaymentInfo.subTotal,
                                  2
                                )}`
                              )
                            ),
                          ])
                        : _c("p", { staticClass: "p-cd-note-summary-amt" }, [
                            _vm._v(
                              _vm._s(_vm.cdNoteForm.paymentCurrency) + " 0.00"
                            ),
                          ]),
                    ]),
                  ]),
                  _c("hr", {
                    staticStyle: { "border-top": "1px solid #F4F4F4" },
                  }),
                  _c("div", { staticClass: "div-cd-note-payment-currency" }, [
                    _c("p", [
                      _vm._v(
                        "Exchange Currency: " +
                          _vm._s(_vm.cdNoteForm.exchangeCurrency || "N.A.") +
                          " (for GST purpose)"
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "div-cd-note-payment-summary" }, [
                    _c("div", [
                      _c("p", [_vm._v("Total Amount before GST")]),
                      _vm._m(1),
                      _vm.cdNotePaymentInfo.exchangeAmount
                        ? _c("p", { staticClass: "p-cd-note-summary-amt" }, [
                            _vm._v(
                              _vm._s(
                                `${
                                  _vm.cdNoteForm.exchangeCurrency
                                } ${_vm.numberWithCommas(
                                  _vm.cdNotePaymentInfo.exchangeAmount,
                                  2
                                )}`
                              )
                            ),
                          ])
                        : _c("p", { staticClass: "p-cd-note-summary-amt" }, [
                            _vm._v(
                              _vm._s(_vm.cdNoteForm.exchangeCurrency) + " 0.00"
                            ),
                          ]),
                    ]),
                    _c("div", [
                      _c("p", [_vm._v("GST Amount")]),
                      _vm._m(2),
                      _vm.cdNotePaymentInfo.exchangeTax
                        ? _c("p", { staticClass: "p-cd-note-summary-amt" }, [
                            _vm._v(
                              _vm._s(
                                `${
                                  _vm.cdNoteForm.exchangeCurrency
                                } ${_vm.numberWithCommas(
                                  _vm.cdNotePaymentInfo.exchangeTax,
                                  2
                                )}`
                              )
                            ),
                          ])
                        : _c("p", { staticClass: "p-cd-note-summary-amt" }, [
                            _vm._v(
                              _vm._s(_vm.cdNoteForm.exchangeCurrency) + " 0.00"
                            ),
                          ]),
                    ]),
                    _c("div", [
                      _c("p", [_vm._v("Total Amount after GST")]),
                      _vm._m(3),
                      _vm.cdNotePaymentInfo.exchangeNoteTotal
                        ? _c("p", { staticClass: "p-cd-note-summary-amt" }, [
                            _vm._v(
                              _vm._s(
                                `${
                                  _vm.cdNoteForm.exchangeCurrency
                                } ${_vm.numberWithCommas(
                                  _vm.cdNotePaymentInfo.exchangeNoteTotal,
                                  2
                                )}`
                              )
                            ),
                          ])
                        : _c("p", { staticClass: "p-cd-note-summary-amt" }, [
                            _vm._v(
                              _vm._s(_vm.cdNoteForm.exchangeCurrency) + " 0.00"
                            ),
                          ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "div-cd-note-payment-summary-note-total" },
                    [
                      _c("div", [
                        _c("h4", [_vm._v("Note Total")]),
                        _vm.cdNotePaymentInfo.noteTotal
                          ? _c("h1", [
                              _vm._v(
                                _vm._s(
                                  `${
                                    _vm.cdNoteForm.paymentCurrency
                                  } ${_vm.numberWithCommas(
                                    _vm.cdNotePaymentInfo.noteTotal,
                                    2
                                  )}`
                                )
                              ),
                            ])
                          : _c("h1", [
                              _vm._v(
                                _vm._s(_vm.cdNoteForm.paymentCurrency) + " 0.00"
                              ),
                            ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("dialog-select-invoice", {
        attrs: {
          isSellerView: _vm.isSellerView,
          dialogSelectInvoice: _vm.dialogSelectInvoice,
          propsInvoiceSelected: _vm.cdNoteInvoiceSelected,
        },
        on: {
          handleConfirmInvoiceClick: _vm.handleConfirmInvoiceClick,
          handleInvoiceDialogClose: function ($event) {
            _vm.dialogSelectInvoice = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }