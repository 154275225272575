import request from '@/services/request'
import apis from '@/services/apis'

export const getCdNotes = (pageSize, pageNumber, query) => {
  let filter = ''
  if (query) {
    filter = `&filter=${query}`
  }
  return request.get({
    url: `${apis.cdNotes}?pageSize=${pageSize}&pageNumber=${pageNumber}${filter}`
  })
}

export const getCdNoteById = (id) => {
  return request.get({
    url: `${apis.cdNotes}/${id}`
  })
}

export const approveRejectCdNote = (data) => {
  return request.post({
    url: apis.batchApproveCdNotes,
    data: data
  })
}

export const verifyCdNoteCA = (data) => {
  return request.post({
    url: apis.verifyCdNoteCA,
    data: data
  })
}

export const createCdNote = (cdNote) => {
  return request.post({
    url: apis.cdNotes,
    data: cdNote
  })
}

export const updateCdNote = (cdNote) => {
  return request.put({
    url: apis.cdNotes,
    data: cdNote
  })
}
